// import React from "react"

// import image320 from "../images/header/320-header.jpg"
// import image768 from "../images/header/768-header.jpg"
// import image1200 from "../images/header/1200-header.jpg"
// import image1920 from "../images/header/1920-header.jpg"
// const Hero = props => {
//   // const { scrollToContent} = props;
 

//   return (
//       <section className="hero">
//             <picture>
//                 <source media="(min-width: 1920px)" srcSet={image1920}  />
//                 <source media="(min-width: 1200px)" srcSet={image1200}  />
//                 <source media="(min-width: 768px)" srcSet={image768}  />
//                 <source media="(min-width: 320px)" srcSet={image320}  />
//                 <img src={image320} alt="Michael Rose"  />
//             </picture>
//       </section>
    
//   );
// };

// // Hero.propTypes = {
// //   scrollToContent: PropTypes.func.isRequired,
// //   backgrounds: PropTypes.object.isRequired,
// //   theme: PropTypes.object.isRequired
// // };

// export default Hero;


import React, {Component} from 'react';
// import { StaticQuery, graphql } from "gatsby"
import Img from 'gatsby-image'

class HeaderImage extends Component {
    render() {

        const { headerImage, headerImageTablet, headerImageMobile, headerClasses, display, headerText } = this.props

        return(
            <div className={`headerImage`}>
                {headerImage &&
                    <Img fluid={headerImage} className="d-none d-lg-block" />
                }
                {headerImageTablet &&
                    <Img fluid={headerImageTablet} className="d-none d-sm-block d-md-block d-lg-none"/>
                }
                {headerImageMobile &&
                    <Img fluid={headerImageMobile} className="d-block d-sm-none"/>
                }
                <h1 className="header-text d-none visible">{headerText}</h1>
                <div className={`blue-rail ${headerClasses ? headerClasses : ''} ${display ? display : ''}`}></div>
            </div>
        )
    }
}

export default HeaderImage;